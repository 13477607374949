import { useRef } from "react";
//import useIsInViewport from '@hooks/usIsInViewport';
import { useInView } from "react-intersection-observer";

const NegociosInteligentes = () => {
  const negociosRef = useRef(null);
  //const inView = useIsInViewport(negociosRef);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <div ref={ref} id="bi" className="container" style={{ backgroundImage: "url(../imgs/trama.svg)", backgroundSize: "auto", backgroundPosition: "left top", backgroundRepeat: "repeat" }}>
        <div className="row btx-row--main">
          <div className="col-12 col-md-5">
            <div className={`btx-item js-item-html btx-html  anmt-item anmt-fadeinl stagger ${inView ? " animated" : ""}`}>
              <h4 className="bi-tit">
                <span>Business</span>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Intelligence
              </h4>
            </div>
            <div className="btx-item js-item-text btx-text bi-txt btx-left-align btx-primary-font">
              <div className="btx-row" style={{ margin: "0 -30px" }}>
                <div className={`btx-text-content btx-col-12 anmt-item anmt-fadeinl ${inView ? " animated" : ""}`} style={{ padding: "0 30px" }}>
                  <div className="btx-text-content-inner">Process of transforming data into information, and information into good actionable insight to ultimately make business decisions. </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="btx-item js-item-html btx-html">
              <div id="bi-img-eng"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NegociosInteligentes;
