
import { useState, useEffect } from 'react'
import { useLocation, useSearchParams, useParams } from 'react-router-dom'
import Footer from '../components/footer';

function Landing(props) {
    const location = useLocation()
    const params = useParams();
    const [data, setData] = useState({
        titulo: "3 tips to boost your sales with marketing analytics",
        header: 'https://takyon.solutions/imgs/header_webinar.jpg',
        form: 'https://takyon.solutions/takyon_form2.html'
    })
    const [searchParams, setSearchParams] = useSearchParams()
    useEffect(() => {
        // console.log(location)
        // console.log(params)
        // const searchParams2 = new URLSearchParams(location)
        // console.log(searchParams2.get('id'))
        // searchParams2.get('code') && setCode(searchParams2.get('code'))
        // console.log(searchParams)
        /*setData({
            titulo: "WEBINAR | Aprende como la AI te ayuda a automatizar tus tareas",
            header: 'https://takyon.solutions/imgs/header_webinar.jpg',
            form: 'https://takyon.solutions/takyon_form2.html'
        })*/
    }, [])

    return <>
        {params && params.code && params.code == "2323760" ?
            <div className="card" style={{width:"80%"}}>
                <div className="card-body">
                    <h5 className="card-title mb-3">Thanks for Submiting!<br /> You can download the E-Book now.</h5>
                    <a style={{ marginTop: "30px" }} className='submit_bot' href="https://takyon.solutions/boost_your_sales_with_marketing_analytics.pdf" target='_blank'>DOWNLOAD</a>
                </div>
            </div>
            :
            <>
                <section className='container'>
                    <>
                        <img src={data.header} width={"100%"} />

                        <div className='row pt-5'>
                            <article className='col-md-8 p-4' style={{ fontSize: "16px", lineHeight: "32px" }}>
                                <div className='row mb-5'>
                                    <div className='col-4'>
                                        <img src="https://takyon.solutions/imgs/tablet.png" width={"100%"} />
                                    </div>
                                    <div className='col-8'>
                                        <h3 style={{ marginBottom: "40px" }} className='misc_landing'>{data.titulo}</h3>
                                        <p>Unlock the Power of Marketing Analytics: Supercharge Your Sales Opportunities! 📈🚀</p>
                                        <p>Ready to take your sales to the next level? Discover the secrets of leveraging cutting-edge marketing analytics in our new ebook!</p>
                                        {(params && params.code && params.code == "2323760") &&
                                            <a style={{ marginTop: "30px" }} className='submit_bot' href="https://takyon.solutions/boost_your_sales_with_marketing_analytics.pdf" target='_blank'>DOWNLOAD</a>
                                        }

                                    </div>
                                </div>

                                <div className='row mt-5 p-3 fondo_cuadri' style={{ backgroundColor: "#f5f5f5" }}>
                                    <div className='col-12'>
                                        <p className='text-center'>📥 Unleash the potential of Business Intelligence and Data Analytics to maximize your marketing impact. Learn how to measure success, track key performance indicators (KPIs), and unlock valuable insights that will propel your business forward.</p>
                                    </div>
                                </div>

                                <div className='row mt-5'>
                                    <div className='col-8'>
                                        <p>📊 Explore the world of Marketing Qualified Leads (MQLs) and Conversion Rates. Dive into the fascinating realm of data-driven decision-making and gain the upper hand in targeting the right audience, optimizing conversions, and boosting your bottom line.</p>
                                        <p>🔍 Compare, analyze, and conquer! Discover how effective benchmarking can revolutionize your marketing strategies. Uncover hidden opportunities, identify trends, and make informed decisions that will give you a competitive edge.</p>
                                    </div>
                                    <div className='col-4'>
                                        <img src="https://takyon.solutions/imgs/lookup.png" width={"100%"} />
                                    </div>
                                </div>

                                <div className='row mt-5'>
                                    <div className='col-6'>
                                        <img src="https://takyon.solutions/imgs/variables.png" width={"100%"} />
                                    </div>
                                    <div className='col-6'>
                                        <p>📣 Harness the power of Email Marketing, Content Marketing, and Social Media Marketing. Explore the dynamic landscape of these engaging channels and uncover how they can drive customer engagement, brand awareness, and conversions.</p>
                                        <p>Ready to revolutionize your marketing game? 🚀📈  Don't miss out on this valuable resource! Download our ebook today and embark on an exciting journey towards unlocking untapped sales opportunities with marketing analytics.</p>
                                    </div>
                                </div>

                                <div className='row mt-5 '>
                                    <div className='col-12 text-center'>
                                        <p className='paragraph pt-5 pb-5'>📚🔒 Don't wait! Grab your copy now and set your business on the path to success. It's time to skyrocket your sales with the power of data-driven marketing.</p>
                                        <h2 className="mt-5" style={{ marginBottom: "50px", color: "#F15134" }}>Remember, knowledge is power, and we're here to empower you!</h2>
                                    </div>
                                    <div className='col-12 text-center'>
                                        {(params && params.code && params.code == "2323760") &&
                                            <a style={{ marginTop: "30px" }} className='submit_bot' href="https://takyon.solutions/boost_your_sales_with_marketing_analytics.pdf" target='_blank'>DOWNLOA NOW!</a>
                                        }
                                    </div>
                                </div>

                            </article>
                            <div className='col-md-4'>
                                <iframe width="100%" height="700" src={data.form}>

                                </iframe>
                            </div>
                        </div>
                    </>

                </section>

                <Footer />
            </>
        }
    </>

}

export default Landing;