import { useRef, useState, useEffect } from "react";
//import useIsInViewport from "@hooks/usIsInViewport";
import { useInView } from 'react-intersection-observer';
import ComoFuncionaMod from '../components/como_funciona_mod'
const ComoFunciona = () => {
  const ComoFuncionaRef = useRef(null);
  //const inView = useIsInViewport(ComoFuncionaRef);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const [active, setActive] = useState(0);

  return (
    <div ref={ref} className="btx-section js-dynamic-navbar btx-p-border-border" id="como-funciona" data-index="5">
      <div className="btx-background" data-type="image" data-parallaxspeed="0" data-contentfade="" data-mobileparallax="">
        <div className="btx-background-overlay btx-p-bg-bg" style={{ backgroundColor: "#222055", opacity: "1" }}></div>
      </div>
     
      <div className="btx-section-wrapper">
        <div className="btx-container--fullwidth">
          <div className="row">
            <div className="col-12">
              <div className={`btx-item js-item-heading btx-heading btx-heading--plain btx-center-align anmt-item anmt-fadein ${inView ? " animated" : ""}`}>
                <h3 className="btx-heading-text btx-secondary-font btx-s-text-color btx-s-text-border">How it works</h3>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="btx-item js-item-html btx-html misc-funciona">
                <div id="misc-funcion-1"></div>
                <div id="misc-funcion-2">
                  <div></div>
                  <div></div>
                </div>
                <div id="misc-funcion-3"></div>
                <div id="misc-funcion-4"></div>
                <div id="misc-funcion-5"></div>
                <div id="misc-funcion-6"></div>
                <div id="misc-funcion-7"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className={`btx-item js-item-html btx-html anmt-item anmt-fadein ${inView ? " animated" : ""}`}>
                <div id="como-funciona-content">
                  
                  <div id="como-pt-1">
                    <div className="slider-como-funciona-txt slick-initialized slick-slider">
                 
                      <div className="slick-list">
                        <div className="slick-track" style={{ opacity: "1", width: "1920px" }}>
                          <div id="funcion-intro-txt" className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" tabindex="0" style={{ width: "480px", position: "relative", left: "0px", top: "0px", zIndex: "999", opacity: active === 0 ? 1 : 0 }}>
                            <p>
                              <span>Try it!</span>
                              <br />
                              Click on Dashboard, Analysis or Report to discover how you can access all of your relevant information.
                            </p>
                          </div>
                          <div id="dashboard-txt" className="slick-slide" data-slick-index="1" aria-hidden="true" tabindex="-1" style={{ width: "480px", position: "relative", left: "-480px", top: "0px", zIndex: "998", opacity: active === 1 ? 1 : 0 }}>
                            <p>
                              <span>Dashboard</span>
                              <br />
                              Main KPIs to provide a quick overview of your business.
                            </p>
                            <p className="volver" onKeyDown={() => console.log("")} onClick={() => setActive(0)}>
                              Back
                            </p>
                          </div>
                          <div id="analisis-txt" className="slick-slide" data-slick-index="2" aria-hidden="true" tabindex="-1" style={{ width: "480px", position: "relative", left: "-960px", top: "0px", zIndex: "998", opacity: active === 2 ? 1 : 0 }}>
                            <p>
                              <span>Analysis</span>
                              <br />
                              Interactive visualizations that reveal the reasons of the results in an intuitive way.
                            </p>
                            <p className="volver" onKeyDown={() => console.log("")} onClick={() => setActive(0)}>
                              Back
                            </p>
                          </div>
                          <div id="reporte-txt" className="slick-slide" data-slick-index="3" aria-hidden="true" tabindex="-1" style={{ width: "480px", position: "relative", left: "-1440px", top: "0px", zIndex: "998", opacity: active === 3 ? 1 : 0 }}>
                            <p>
                              <span>Report</span> <br />
                              Detailed reports to reach transaction level data easier and faster.
                            </p>
                            <p className="volver" onKeyDown={() => console.log("")} onClick={() => setActive(0)}>
                              Back
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ComoFuncionaMod active={active} setActiveAct={setActive} />

                  <div id="como-pt-3">
                    <img id="dashboard-2" src="../imgs/dashboard-data-eng.png" alt="dashboard-txt" width="100%" style={{ opacity: active === 1 ? 1 : 0 }} />
                    <img id="analisis-2" src="../imgs/analisis-data-ing.png" alt="analisis-txt" width="100%" style={{ opacity: active === 2 ? 1 : 0 }} />
                    <img id="reporte-2" src="../imgs/report-data-eng.png" alt="reporte-txt" width="100%" style={{ opacity: active === 3 ? 1 : 0 }} />
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className={`btx-item js-item-html btx-html anmt-item anmt-fadein ${inView ? " animated" : ""}`}>
                <div id="como-funciona-content-mobile">
                  <div id="btn-mobile">
                    <div id="dashboard-btn-mobile" style={{cursor:"pointer"}} className={active <= 1 ?"active":""} onKeyDown={() => console.log("")} onClick={() => setActive(1)}>
                      <p>D.</p>
                    </div>
                    <div id="analisis-btn-mobile" style={{cursor:"pointer"}} className={active === 2 ?"active":""} onKeyDown={() => console.log("")} onClick={() => setActive(2)}>
                      <p>A.</p>
                    </div>
                    <div id="reporte-btn-mobile" style={{cursor:"pointer"}} className={active === 3 ?"active":""} onKeyDown={() => console.log("")} onClick={() => setActive(3)}>
                      <p>R.</p>
                    </div>
                  </div>

                  <div className="slider-como-funciona-txt-mobile slick-initialized slick-slider col-12">
                    <div className="slick-list" style={{marginBottom:"80px"}}>
                      <div className="slick-track" style={{ opacity: 1 }}>
                        <div className="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" tabindex="0" style={{position: active <= 1 ? "relative" : "absolute",   left: "0px", top: "0px", zIndex: 999, opacity: active <= 1 ? 1 : 0 }}>
                          <div id="dashboard-txt-mobile">
                            <p>
                              <span>Dashboard</span>
                              <br />
                              Main KPIs to provide a quick overview of your business.
                            </p>
                          </div>
                        </div>
                        <div className="slick-slide" data-slick-index="1" aria-hidden="true" tabindex="-1" style={{ position: active === 2 ? "relative" : "absolute",  left: "0px", top: "0px", zIndex: 998, opacity: active === 2 ? 1 : 0 }}>
                          <div id="analisis-txt-mobile">
                            <p>
                              <span>Analysis</span>
                              <br />
                            </p>
                          </div>
                        </div>
                        <div className="slick-slide" data-slick-index="2" aria-hidden="true" tabindex="-1" style={{ position: active === 3 ? "relative" : "absolute",  left: "0px", top: "0px", zIndex: 998, opacity: active === 3 ? 1 : 0 }}>
                          <div id="reporte-txt-mobile">
                            <p>
                              <span>Report</span> <br />
                              Detailed reports to reach transaction level data easier and faster.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ComoFuncionaMod active={active===0?1:active} setActiveAct={setActive} />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComoFunciona;
