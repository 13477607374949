import { useRef, useEffect, useState } from "react";
//import useIsInViewport from "@hooks/usIsInViewport";
import { useInView } from "react-intersection-observer";

import useAutochange from "../hooks/useAutochange";

const Marquee = () => {
  const marqueeRef = useRef(null);
  //const isIntersecting = useIsInViewport(marqueeRef);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const active = useAutochange();

  return (
    <div ref={ref} id="intro">
      <div className="container  " style={{ height: "753px" /*, lineHeight: "753px" */ }}>
        <div className="row align-items-center justify-content-center justify-content-md-start" style={{ height: "753px" }}>
          <div className="col-10 col-md-6  " style={{ position: "relative" }}>
            <div className="s-slider">
              <div style={{ position: active === 0 ? "absolute" : "absolute", left: "0px", top: "0px", zIndex: 998, opacity: active === 0 ? 1 : 0, transition: "opacity 2000ms ease 0s" }}>
                <p>
                  <span>
                    Explore, manage and share <br />
                    your data anywhere anytime
                  </span>
                  <br />
                  All the information you need at the reach of your hand.
                </p>
              </div>
              <div style={{ position: active === 1 ? "absolute" : "absolute", left: "0px", top: "0px", zIndex: 998, opacity: active === 1 ? 1 : 0, transition: "opacity 2000ms ease 0s" }}>
                <p>
                  <span>
                    Release the knowledge <br />
                    hidden in your data
                  </span>
                  <br />
                  We transform data into valuable information.
                </p>
              </div>
              <div style={{ position: active === 2 ? "absolute" : "absolute", left: "0px", top: "0px", zIndex: 999, opacity: active === 2 ? 1 : 0, transition: "opacity 2000ms ease 0s" }}>
                <p>
                  <span>
                    Take data-driven decisions <br />
                    quicker and easier
                  </span>
                  <br />
                  Interactive taylor-made boards to help in your every-day <br />
                  decision making.
                </p>
              </div>
            </div>

            <div id="conoce-mas">
              <a href="http://takyon.com.ar/en/home-2/#nosotros">Learn more</a>
            </div>

            {1 === 4 && (
              <div className="btx-item js-item-html btx-html">
                <div className="s-slider slick-initialized slick-slider">
                  <div className="slick-list draggable">
                    <div className="slick-track" style={{ opacity: 1 }}>
                      <div className="slick-slide" data-slick-index="0" aria-hidden="true" style={{ position: "relative", left: "0px", top: "0px", zIndex: 998, opacity: 0, transition: "opacity 1000ms ease 0s" }}>
                        <p>
                          <span>
                            Explore, manage and share <br />
                            your data anywhere anytime
                          </span>
                          <br />
                          All the information you need at the reach of your hand.
                        </p>
                      </div>
                      <div className="slick-slide" data-slick-index="1" aria-hidden="true" style={{ position: "relative", left: "0px", top: "0px", zIndex: 998, opacity: 0, transition: "opacity 1000ms ease 0s" }}>
                        <p>
                          <span>
                            Release the knowledge <br />
                            hidden in your data
                          </span>
                          <br />
                          We transform data into valuable information.
                        </p>
                      </div>
                      <div className="slick-slide slick-current slick-active" data-slick-index="2" aria-hidden="false" style={{ position: "relative", left: "0px", top: "0px", zIndex: 999, opacity: 1 }}>
                        <p>
                          <span>
                            Take data-driven decisions <br />
                            quicker and easier
                          </span>
                          <br />
                          Interactive taylor-made boards to help in your every-day <br />
                          decision making.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="conoce-mas">
                  <a href="http://takyon.com.ar/en/home-2/#nosotros">Learn more</a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Marquee;
