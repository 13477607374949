const ComoFuncionaMod = (props) => {
    return <div id="como-pt-2" style={{position:"relative"}}>
        <div className="slider-como-funciona-img">
            <div id="funcion-intro-img" style={{ width: "270px", position: "absolute", top: 0, opacity: props.active === 0 ? 1 : 0, transition: "opacity 1000ms ease 0s" }}>
                <div id="dashboard" onKeyDown={() => console.log("")} onClick={() => props.setActiveAct(1)}></div>
                <div id="analisis" onKeyDown={() => console.log("")} onClick={() => props.setActiveAct(2)}></div>
                <div id="reporte" onKeyDown={() => console.log("")} onClick={() => props.setActiveAct(3)}></div>
                <img src="../imgs/como-funciona-1-final-eng.png" alt="conocé nuestra interfaz" width="250px" />
            </div>
            <div id="dashboard-img" style={{ width: "270px", position: "absolute", top: 0, opacity: props.active === 1 ? 1 : 0, transition: "opacity 1000ms ease 0s" }}>
                <img src="../imgs/dashboard-eng-graph.jpg" alt="dashboard" width="100%" />
            </div>
            <div id="analisis-img" style={{ width: "270px", position: "absolute", top: 0, opacity: props.active === 2 ? 1 : 0, transition: "opacity 1000ms ease 0s" }}>
                <img src="../imgs/analysis-eng-graph.jpg" alt="analisis" width="100%" />
            </div>
            <div id="reporte-img" style={{ width: "270px", position: "absolute", top: 0, opacity: props.active === 3 ? 1 : 0, transition: "opacity 1000ms ease 0s" }}>
                <img src="../imgs/report-eng-graph.jpg" alt="reporte" width="100%" />
            </div>
        </div>

    </div>
}

export default ComoFuncionaMod