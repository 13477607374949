"use client";
import { useRef } from "react";
//import useIsInViewport from '@hooks/usIsInViewport';
import { useInView } from "react-intersection-observer";

const Footer = () => {
  const footerRef = useRef(null);
  //const inView = useIsInViewport(footerRef);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  return (
    <footer ref={ref} className="section-wrapper" id="footer">
      <div className="container pt-5 pb-5">
        <div className="row justify-content-center">
          <div className="col-11 col-md-4">
            <div className={`item js-item-html html anmt-item anmt-fadein stagger d1 ${inView ? " animated" : ""}`}>
              <h6>Takyon</h6>
              <p>Our name Takyon comes from the field of quantum physics. "Tachyons" are hypothetical particles that travel faster than the speed of light; if such particles exist they would violate laws of physics and causality.</p>
              <br />
              <p>Let’s work together to break through the boundaries of conventional thinking!</p>
              <br />
              <p>© Takyon 2020</p>
            </div>
          </div>
          <div className="col-11 col-md-4">
            <div className={`item js-item-html html  anmt-item anmt-fadein stagger d2 ${inView ? " animated" : ""}`} id="contacto-footer">
              <h6>Contact</h6>
              <p>Buenos Aires, Argentina</p>
              <br />
              <div>
                <p>Phone:</p>
              </div>
              <div>
                <p>
                  +549 113 166 3095 <br /> +549 115 978 3853{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-11 col-md-4">
            <div className={`item js-item-html html anmt-item anmt-fadein stagger d2 ${inView ? " animated" : ""}`} >
              <h6>Follow Us</h6>
              <p>Follow us to find out the latest news and solutions.</p>
              <div id="redes">
                <div>
                  <a href="https://www.linkedin.com/company/takyonlatam" target="_blank">
                    <img src="../imgs/linkedin.svg" alt="linkedin" width="100%" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
