
import Footer from '../components/footer';

function WebBinarCierre() {

    return (
        <>
            <section className='container'>
                <img src='./imgs/header_webinar.jpg' width={"100%"} />
                <div className='row'>
                    <article className='col-md-8'>
                        <h2>WEBINAR | Aprende como la AI te ayuda a automatizar tus tareas</h2>
                        <h6>¡Muchísimas gracias por participar en nuestro webinar sobre AI!</h6>
                        <p className="pb-2 pt-4" style={{ fontSize: "18px" }}>Para aquellos que deseen obtener su prueba de concepto personalizada o tengan más consultas, los invitamos a dejar sus datos. Nos pondremos en contacto con ustedes a la brevedad para resolver cualquier inquietud que puedan tener.</p>
                        <p className="pb-2 mb-4" style={{ fontSize: "18px" }}>Gracias nuevamente por acompañarnos en esta jornada. Seguinos en nuestras redes para seguir aprendiendo sobre el fascinante mundo de la Inteligencia Artificial.</p>
                        <h3>¡Hasta pronto!</h3>
                    </article>
                    <div className='col-md-4'>
                        <iframe width="100%" height="700" src='https://takyon.solutions/takyon_form_cierre.html'></iframe>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default WebBinarCierre;