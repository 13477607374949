import { useRef } from "react";
//import useIsInViewport from "@hooks/usIsInViewport";
import { useInView } from "react-intersection-observer";

const Nosotros = () => {
  const nosotrosRef = useRef(null);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  return (
    <div style={{ backgroundColor: "#f5f5f5", opacity: 1 }} id="nosotros" className="pb-2">
      <div ref={ref} className="container pt-5 pb-5">
        <div className="row btx-row--main mt-3">
          <div className="col-12">
            <div className={`btx-item js-item-heading btx-heading btx-heading--plain btx-center-align anmt-item anmt-fadein ${inView ? " animated" : ""}`}>
              <h3 className="btx-heading-text btx-secondary-font btx-s-text-color btx-s-text-border"> About us </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className={`btx-item js-item-html btx-html anmt-item anmt-fadein stagger d1 ${inView ? " animated" : ""}`}>
              <div className="num-nosotros">
                <p>/ 01</p>
              </div>
            </div>

            <div className={`btx-item js-item-html btx-html anmt-item anmt-fadein stagger d1 ${inView ? " animated" : ""}`}>
              <p className="txt-nosotros">
                Agile and innovative organization specialized in{" "}
                <span>
                  <u>Business Intelligence</u>
                </span>{" "}
                y{" "}
                <span>
                  <u>Data Analytics</u>
                </span>
                .
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className={`btx-item js-item-html btx-html anmt-item anmt-fadein stagger d2 ${inView ? " animated" : ""}`}>
              <div className="num-nosotros num-even">
                <p>/ 02</p>
              </div>
            </div>

            <div className={`btx-item js-item-html btx-html anmt-item anmt-fadein stagger d2 ${inView ? " animated" : ""}`} style={{ marginLeft: "0px" }}>
              <p className="txt-nosotros">
                We deliver business intelligence
                <span>
                  <u>solutions</u>
                </span>{" "}
                through the use of{" "}
                <span>
                  <u>high-value technologies</u>
                </span>
                .
              </p>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-md-6">
            <div className={`btx-item js-item-html btx-html anmt-item anmt-fadein stagger d3 ${inView ? " animated" : ""}`}>
              <div className="num-nosotros">
                <p>/ 03</p>
              </div>
            </div>

            <div className={`btx-item js-item-html btx-html anmt-item anmt-fadein stagger d3 ${inView ? " animated" : ""}`}>
              <p className="txt-nosotros">
                We{" "}
                <span>
                  <u>partner</u>
                </span>{" "}
                with you in the transformation towards a{" "}
                <span>
                  <u>data-driven</u>
                </span>{" "}
                organization.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className={`btx-item js-item-html btx-html anmt-item anmt-fadein stagger d4 ${inView ? " animated" : ""}`}>
              <div className="num-nosotros num-even">
                <p>/ 04</p>
              </div>
            </div>
            <div className={`btx-item js-item-html btx-html anmt-item anmt-fadein stagger d4 ${inView ? " animated" : ""}`}>
              <p className="txt-nosotros">
                {" "}
                We empower people, enabling them to make{" "}
                <span>
                  <u> decisions based on data</u>
                </span>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nosotros;
