import { useRef, useState, useEffect } from "react";
//import useIsInViewport from "@hooks/usIsInViewport";
import { useInView } from "react-intersection-observer";

const ComoTrabajamos = () => {
  const comoTrabajamosRef = useRef(null);
  //const inView = useIsInViewport(comoTrabajamosRef);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const [active, setActive] = useState(0);

  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <div ref={ref} className="container pt-4" id="como-trabajamos">
        <div className="text-center mt-5">
          <h3 className="btx-secondary-font">How we work</h3>
        </div>
        <div className="row">
          <div className="col-12 col-md-5">
            <div>
              <h4>
                <span>Actionable</span>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Insights
              </h4>
            </div>

            <div className="como-trabajamos-txt">
              <div className="row" style={{ margin: "0 -30px" }}>
                <div className={`btx-text-content col-12 anmt-item anmt-fadeinl ${inView ? " animated" : ""}`} style={{ padding: "0 30px" }}>
                  <div className="btx-text-content-inner">It is important to know how to make the most of the data available and identify the lack of information to take data-driven decisions. </div>
                </div>
              </div>
            </div>

            <div className={`btx-item js-item-html btx-html anmt-item anmt-fadein${inView ? " animated" : ""}`}>
              <div id="conocimientos">
                <div>
                  <p>
                    <span>/01</span>
                    <br />
                    Data extraction from multiple sources
                  </p>
                </div>
                <div>
                  <p>
                    <span>/02</span>
                    <br />
                    Data transformation into valuable information
                  </p>
                </div>
                <div>
                  <p>
                    <span>/03</span>
                    <br />
                    Insight generation to improve decision making
                  </p>
                </div>
                <div>
                  <p>
                    <span>/04</span>
                    <br />
                    All Key Performance Indicators in one place
                  </p>
                </div>
              </div>
            </div>

            <div className="btx-item js-item-image btx-image btx-center-align" id="miscelanea1">
              <div className="btx-image-container">
                <div className="btx-media-wrapper">
                  <div className="btx-media-wrapper-inner">
                    <img src="./imgs/miscelanea1.png" alt="" width="419" height="521" sizes="(max-width:419px) 100vw, 419px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5">
            <div id="como-trabjamos-img"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComoTrabajamos;
