import { useEffect, useState } from 'react';

const useAutochange = () => {
  const [act, setAct] = useState(0);

  useEffect(() => {
    const intervalId = setInterval((active) => {
      setAct((prevActive) => {
        return prevActive < 2 ? prevActive + 1 : 0;
      });
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  return act;
};

export default useAutochange;
