import { useRef, useState, useEffect } from "react";
//import useIsInViewport from "@hooks/usIsInViewport";
import { useInView } from "react-intersection-observer";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";

const Servicios = () => {
  const serviciosRef = useRef(null);
  //const inView = useIsInViewport(serviciosRef);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  /* const opt = {
         rewind: true,
         width: "100%",
         gap: '1rem',
         perPage: 5
     }*/

  const data = [
    {
      boton: "Analysis",
      titulo: (
        <p>
          <span>/01</span> Analysis
        </p>
      ),
      cont: (
        <p>
          <span>Objectives and Business Analysis</span>
          <br />
          We work closely with you to fully understand your business, the decisions you need to make and the information required to take them.
        </p>
      ),
    },
    {
      boton: "Solution",
      titulo: (
        <p>
          <span>/02</span> Solution
        </p>
      ),
      cont: (
        <p>
          <span>Design and development</span>
          <br />
          We cover the specific needs of each client, leading the development and implementation to deliver a custom-made solution.
        </p>
      ),
    },
    {
      boton: "Continual Improvement",
      titulo: (
        <p>
          <span>/03</span> Continual Improvement
        </p>
      ),
      cont: (
        <p>
          <span>Involvement of the client in the whole process</span> <br />
          We incorporate user feedback at each stage to ensure that the solution meets their expectations.
        </p>
      ),
    },
    {
      boton: "Training",
      titulo: (
        <p>
          <span>/04</span> Training
        </p>
      ),
      cont: (
        <p>
          <span>Evangelization and Empowerment</span>
          <br />
          We train our clients to become data literate and learn how to deliver advanced analysis.
        </p>
      ),
    },
  ];
  const [active, setActive] = useState(0);

  //className={` ${inView?" animated":""}`}
  return (
    <div ref={ref} className="" id="servicios" data-index="3" style={{ backgroundColor: "#222055", opacity: 1 }}>
      <div className="btx-section-wrapper">
        <div className="container">
          <div className="row mb-5">

            <div className="col-12">
              <div className={`btx-item js-item-heading btx-heading btx-heading--plain btx-center-align anmt-item anmt-fadein ${inView ? " animated" : ""}`}>
                <h3 className="btx-heading-text btx-secondary-font btx-s-text-color btx-s-text-border"> Services</h3>
              </div>

              <div className="btx-item js-item-text btx-text txt-servicios-intro btx-center-align btx-primary-font" style={{ marginTop: "40px" }}>
                <div className="row" style={{ margin: "0 -30px" }}>
                  <div className={`btx-text-content col-12 anmt-item anmt-fadein ${inView ? " animated" : ""}`} style={{ padding: "0 30px" }}>
                    <div className="btx-text-content-inner">
                      We use agile methodologies, involving users throughout the entire
                      <br />
                      process to add their experience and feedback to the solution.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12">
              <div className="d-flex menu-servicios">
                {data.map((e, i) => {
                  return (
                    <div className={`w-25 ${active === i ? " active" : ""}`} onClick={() => setActive(i)} key={{ i }} onKeyDown={() => console.log("")}>
                      {e.boton}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="row pt-3 justify-content-center justify-content-md-start">
            <div className="d-none d-md-block col-6">
              <img src="../imgs/servicios-slider-img.png" />
            </div>

            <div className="col-10 col-md-6 slider-servicios" style={{ position: "relative",minHeight:"250px" }}>
              {data.map((e, i) => {
                return (
                  <div className="" style={{ position: "absolute", opacity: active === i ? 1 : 0, transition: "opacity 1000ms ease 0s" }} key={{ i }}>
                    {e.titulo}
                    {e.cont}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Servicios;
