
import Footer from '../components/footer';

function WebBinar() {

    return (
        <>
            <section className='container'>
                <img src='./imgs/header_webinar.jpg' width={"100%"} />
                <div className='row'>
                    <article className='col-md-8'>
                        <h2>WEBINAR | Aprende como la AI te ayuda a automatizar tus tareas</h2>
                        <p className="pb-2" style={{ fontSize: "18px" }}>¿Estás cansado de que el trabajo manual te lleve demasiado tiempo? ¡Con Al, podemos solucionar eso! Aprende cómo en nuestro próximo webinar este 28 de mayo.</p>

                        <ul>
                            <li>Conocerás qué son los bots que automatizan tareas.</li>
                            <li>Descubrirás las mejores herramientas de automatización.</li>
                            <li>Te mostramos como automatizar tus tares del día a día como la carga de facturas con inteligencia artificial.</li>
                        </ul>

                        <p className="pb-3" style={{ fontSize: "18px" }}><b>¡Registrate ahora y empezá a transformar tu manera de trabajar!</b></p>

                        <h4>Fecha y horario del evento:</h4>
                        <p>Martes 28 de Mayo del 2024</p>
                        <div>
                            <i className="far fa-clock me-3"></i>3 pm Argentina - Chile
                        </div>
                        <div>
                            <i className="far fa-clock me-3"></i>1 pm Colombia - Perú - México
                        </div>
                        <div className='d-flex justify-content-between mt-4 ' style={{ maxWidth: "400px" }}>
                            <div><b>Duracion: </b>30 min</div>
                            <div><b>Evento Online: </b>Zoom</div>
                        </div>

                        <div className='row justify-content-around pb-4 pt-4'>
                            <div className="col-4 justify-content-center text-center">
                                <img src="./imgs/ivan.png" style={{ width: '100%', maxWidth: "300px" }} />
                                <h3>Ivan Morgan</h3>
                                <h7>Chief Revenue Officer</h7>
                            </div>
                            <div className="col-4 text-center">
                                <img src="./imgs/elias.png" style={{ width: '100%', maxWidth: "300px" }} />
                                <h3>Elias Rolon</h3>
                                <h7>RPA Engineer</h7>
                            </div>

                        </div>

                    </article>
                    <div className='col-md-4'>
                        <iframe width="100%" height="700" src='https://takyon.solutions/takyon_form3.html'></iframe>
                    </div>
                </div>

            </section>

            <Footer />
        </>
    );
}

export default WebBinar;