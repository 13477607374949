const header = (props) => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg fixed-top " style={{backgroundColor:"#fff"}}>
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src="./imgs/takyon-logo.svg" alt="Logo" width="118" className="d-inline-block align-text-top" />
          </a>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i className="fas fa-ellipsis-v"></i>
          </button>

          <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
            <ul className="navbar-nav ">
              <li className="nav-item">
                <a className="nav-link hover-underline-animation" style={{paddingLeft:"1.2rem",paddingRight:"1.2rem"}} href="#nosotros">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link hover-underline-animation"  style={{paddingLeft:"1.2rem",paddingRight:"1.2rem"}} href="#servicios">
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link hover-underline-animation"  style={{paddingLeft:"1.2rem",paddingRight:"1.2rem"}} href="#como-trabajamos">
                  How We Work
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link hover-underline-animation"  style={{paddingLeft:"1.2rem",paddingRight:"1.2rem"}} href="#como-funciona">
                  How It Works
                </a>
              </li>
              {1===3 &&
              <li className="nav-item">
                <a className="nav-link hover-underline-animation" href="#">
                  Our Team
                </a>
              </li>
              }
              {1===3 &&
              <li className="nav-item">
                <a className="nav-link hover-underline-animation" href="#">
                  Work With Us
                </a>
              </li> 
              }
              <li className="nav-item">
                <a className="nav-link hover-underline-animation" style={{paddingLeft:"1.2rem",paddingRight:"1.2rem"}} href="#contacto">
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div className="d-none d-lg-block" style={{width:"134px"}}></div>
        </div>
      </nav>
    </header>
  );
};

export default header;
