import { useRef, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import axios from "axios";

const Contacto = () => {
  const contactoRef = useRef(null);
  const [enviando, setEnviando] = useState(false)
  const [data, setData] = useState({
    nombre: "",
    correo: "",
    titulo: "",
    mensaje: ""
  })

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const enviaContacto = async (e) => {
    e.preventDefault()
    if (data.nombre === "") {
      alert("Debe completar el nombre")
    } else if (data.correo === "") {
      alert("Debe completar el mail")
    } else if (data.titulo === "") {
      alert("Debe completar el titulo")
    } else if (data.mensaje === "") {
      alert("Debe completar el mensaje")
    } else {
      try {
        setEnviando(true)
        await axios.post('https://us-central1-takyon-b5ba0.cloudfunctions.net/enviaMail', data)
        setData({
          nombre: "",
          correo: "",
          titulo: "",
          mensaje: ""
        })
      } catch (error) {
        console.log(error)
      } finally {
        setEnviando(false)
      }
    }

  }

  const cambia = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  };

  return (
    <div ref={ref} className="btx-section js-dynamic-navbar btx-p-border-border" id="contacto" data-index="8">
      <div className="btx-background" data-type="image" data-parallaxspeed="0" data-contentfade="" data-mobileparallax="">
        <div className="btx-background-overlay btx-p-bg-bg" style={{ backgroundColor: "#222055", opacity: "1" }}></div>
      </div>
      <div className="btx-section-wrapper">
        <div className="container">
          <div className="row btx-row--main">
            <div className="col-12">
              <div className={`btx-item js-item-heading btx-heading btx-heading--plain btx-center-align anmt-item anmt-fadein  ${inView ? " animated" : ""}`}>
                <h3 className="btx-heading-text btx-secondary-font btx-s-text-color btx-s-text-border">Contact</h3>
              </div>

              <div className="btx-item js-item-html btx-html">
                <p className="txt-contacto">
                  We are ready to answer any questions.
                  <br />
                  <span>Contact us to coordinate a demo!</span>
                </p>
              </div>

              <div className="btx-item js-item-html btx-html">
                <div role="form" className="wpcf7" id="wpcf7-f3892-p3462-o3" lang="es-ES" dir="ltr">
                  <div className="screen-reader-response">
                    <p role="status" aria-live="polite" aria-atomic="true"></p>
                    <ul></ul>
                  </div>
                  <form ref={contactoRef} className="" >
                    <p>
                      <label>
                        <br />
                        <span className="wpcf7-form-control-wrap your-name">
                          <input onChange={(e) => cambia(e)} value={data.nombre} type="text" name="nombre" size="40" className="wpcf7-form-control wpcf7-text" aria-invalid="false" placeholder="Name" />
                        </span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <br />
                        <span className="wpcf7-form-control-wrap your-email">
                          <input onChange={(e) => cambia(e)} value={data.correo} type="email" name="correo" size="40" className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" placeholder="Email" />
                        </span>{" "}
                      </label>
                    </p>
                    <p>
                      <label>
                        <br />
                        <span className="wpcf7-form-control-wrap subject">
                          <input onChange={(e) => cambia(e)} value={data.titulo} type="text" name="titulo" size="40" className="wpcf7-form-control wpcf7-text" aria-invalid="false" placeholder="Title" />
                        </span>
                      </label>
                    </p>
                    <p>
                      <label>
                        <br />
                        <span className="wpcf7-form-control-wrap your-message">
                          <textarea onChange={(e) => cambia(e)} value={data.mensaje} name="mensaje" cols="40" rows="10" className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="Message"></textarea>
                        </span>
                      </label>
                    </p>
                    <p>
                      {enviando ?
                      <div className="submit_bot">Wait a second...</div>
                      :
                      <button onClick={(e) => enviaContacto(e)} className="submit_bot" >Submit</button>
                      }
                      
                      <span className="ajax-loader"></span>
                    </p>
                    <div className="wpcf7-response-output" aria-hidden="true"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contacto;
