import React from 'react';
import ReactDOM from 'react-dom/client';

import "./styles/style-custom.css";
import "./styles/bootstrap.min.css";
import "./styles/main.min.css";
import "@splidejs/react-splide/css";
import "./styles/styles.css";
import "./styles/animate.css";

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
