import { useRef } from "react";
//import useIsInViewport from '@hooks/usIsInViewport';
import { useInView } from "react-intersection-observer";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";

const Clientes = () => {
  const clientesRef = useRef(null);
  //const isIntersecting = useIsInViewport(clientesRef);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const opt = {
    type: "loop",
    rewind: true,
    width: "100%",
    gap: "1rem",
    perPage: 5,
    pagination: false,
    autoplay: true,
    speed: 1000,
    perMove: 1,
    interval: 1000,
    breakpoints: {
      1024: {
        perPage: 5,
       
      },
      780: {
        perPage: 4,
       
      },
      550: {
        perPage: 2,
    
      },
      370: {
        perPage: 1,
  
      },
    },
  };

  const clients = [
    { nombre: "", logo: "just__logo-300x138.png" },
    { nombre: "", logo: "pow_logo_-300x138.png" },
    { nombre: "", logo: "logo_x28-300x138.png" },
    { nombre: "", logo: "laritirigoyen_logo-300x138.png" },
    { nombre: "", logo: "logo_gm-300x138.png" },
    { nombre: "", logo: "galiciaseguros_logo-300x138.png" },
    { nombre: "", logo: "logo_banco-m-300x138.png" },
    { nombre: "", logo: "just__logo-300x138.png" },
    { nombre: "", logo: "pow_logo_-300x138.png" },
    { nombre: "", logo: "logo_x28-300x138.png" },
    { nombre: "", logo: "laritirigoyen_logo-300x138.png" },
    { nombre: "", logo: "logo_gm-300x138.png" },
    { nombre: "", logo: "galiciaseguros_logo-300x138.png" },
    { nombre: "", logo: "logo_banco-m-300x138.png" },
    { nombre: "", logo: "just__logo-300x138.png" },
    { nombre: "", logo: "pow_logo_-300x138.png" },
    { nombre: "", logo: "logo_x28-300x138.png" },
    { nombre: "", logo: "laritirigoyen_logo-300x138.png" },
    { nombre: "", logo: "logo_gm-300x138.png" },
  ];
  return (
    <div ref={ref} style={{ backgroundColor: "#01a2dd" }} className="container-fluid pt-5 pb-5">
      <div className="row">
        <div className="col-12">
          <div className={`btx-item js-item-heading btx-heading btx-heading--plain btx-center-align anmt-item anmt-fadein ${inView ? " animated" : ""}`}>
            <h3 className="btx-heading-text btx-secondary-font btx-s-text-color btx-s-text-border">
              <span style={{ color: "#fff" }}>Brands that trust us</span>
            </h3>
          </div>
        </div>
      </div>
      <Splide aria-label="My Favorite Images" options={opt}>
        {clients.map((c, i) => {
          return (
            <SplideSlide key={i}>
              <img src={`../imgs/${c.logo}`} alt={`${c.nombre}`} width="100%" />
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
};

export default Clientes;
