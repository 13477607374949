import Header from '../components/header';
import Marquee from '../components/marquee';
import NegociosInteligentes from '../components/negocios_inteligentes';
import ComoTrabajamos from '../components/como_trabajamos';
import Clientes from '../components/clientes';
import Nosotros from '../components/nosotros';
import Servicios from '../components/servicios';
import Contacto from '../components/contacto';
import ComoFunciona from '../components/como_funciona';
import Footer from '../components/footer';

function Home() {
    return (
        <>
            <Header />
            <Marquee />
            <Nosotros />
            <NegociosInteligentes />
            <Servicios />
            <ComoTrabajamos />
            <ComoFunciona />
            <Clientes />
            <Contacto />
            <Footer />
        </>
    );
}

export default Home;
