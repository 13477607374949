import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './components/home';
import Webinar from './components/landing';
import Landing from './components/landing_mod';
import WebBinarCierre from './components/landing_cierre'; 

function App() {
  return (
    <BrowserRouter >
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/webinar" element={<Webinar />} />
        <Route path="/cierre" element={<WebBinarCierre />} />
        <Route path="/landing/:id/:code?" element={<Landing />} />
      </Routes>
    </BrowserRouter>


  );
}

export default App;
